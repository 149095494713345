.hero-section {
  background: url(../../src/assets/img/BG\ img.png);
  background-position: right -100px;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .row-links {
    justify-content: start !important;
  }
}
@media (max-width: 600px) {
  .splash-2 {
    margin: 97px 50px !important;
  }
  .splash-1 {
    margin: 97px 50px 0px !important;
  
}
  .download-links {
    margin-top: 20px !important;
  }
  .hero-section {
    background: url(../../src/assets/img/bg-img-mini.png);
    background-position: right 0px;
    background-repeat: no-repeat;
  }
  .hero-header {
    text-align: center;
    font-size: 2rem !important;
    line-height: 2rem !important;
  }
  .hero-subtext {
    text-align: center;
  }
  .caption-ctn {
    margin-top: 0px !important;
  }
}
.hero-header {
  font-weight: 700;
  font-size: 4rem ;
    line-height: 4.5rem ;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #27316d;
}

.yellow-text {
  color: #fdd500;
}
.hero-subtext {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #27316d;
  text-align: left;
}
.thicker-text {
  font-weight: 500;
}
.caption-ctn {
  margin-top: 9.6rem;
}
.play-link {
  width: 210px;
  height: 60px;
  background: #000000;
  border-radius: 15px;
  box-shadow: none !important;
}
.get-text {
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
  text-align: start;
}
.store-text {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #ffffff;
  white-space: nowrap;
}
.download-links {
  margin-top: 4rem;
}
.splash-1 {
  /* margin-top: 68px; */
}
.splash-2 {
  margin-top: 97px;
  margin-right: 30px;
}
.row-alt:after {
  content: "";
  width: 70%;
  display: block;
  position: relative;
  margin-right: auto;
  margin-top: 55px;
  margin-left: auto;
  border: 0.01px solid #e0dddda4;
}
