nav {
  box-shadow: 0px 4px 4px rgba(224, 224, 224, 0.25);
}

.logo-icon {
  max-height: 50px;
}
.btn-purple {
  width: 170px;
  height: 55px;
  background: #fdd500;
  border-radius: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  box-shadow: none !important;
  color: #27316d;
  margin-top: auto;
}
.navbar-toggler {
  box-shadow: none !important;
}
.nav-link {
  text-align: center !important;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: #27316d;
}
.nav-link:hover {
  color: #323f88;
}
.nav-item {
  text-align: center;
  display: flex;
  align-items: center;
  margin-right: 42px;
}
@media (min-width: 992px) {
  .navbar-collapse {
    display: flex;

    justify-content: end !important;
  }
  .nav-item {
    display: flex !important;
    text-align: right;
    justify-content: end !important;

  }
  .nav-item a{
    display: block !important;
  }
}
@media (max-width: 992px) {
 
  .nav-item {
    display: flex !important;
    text-align: right;
    justify-content: end !important;
margin-right: 0px;
  }
  .nav-item a{
    display: block !important;
  }
}
