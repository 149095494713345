.subscribe-caption {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #27316d;
}
.subscribe-subtext {
  text-align: center;
  letter-spacing: 0.05em;
  color: #27316d;
}
.subscribe-form {
  margin: 50px auto;
  background: #ffffff;
  border: 0.3px solid #c4c4c4;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 526px;
  height: 76px;
  padding-left: 60px;
  background: url(../../src/assets/img/message.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
}
.subscribe-input {
  border: none !important;
  border-bottom: 0.2px solid #c4c4c498 !important;
  height: 47px;
  border-radius: 0;
  padding: 0;
  width: 60%;
  position: relative;
  box-shadow: none !important;
  float: left;
}
.subscribe-btn {
  text-align: center;
  padding: 18px;
  align-items: center;
  box-shadow: none !important;
  float: right;
  width: 163px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.05em;

  color: #000000;
  height: 57px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background: #fdd500;
}

.insta-img{
  padding-top: -10px;
}
.subscribe-caption {
  margin-top: 100px;
}
@media (max-width: 318px) {
  .subscribe-form {   
    transform: scale(0.57);
    margin-left: -130px !important;
    margin-top: 30px;
  }
}
@media (max-width: 570px) {
  .subscribe-caption {
  margin-top: 50px;
}
  .subscribe-form {   
    transform: scale(0.67);
    margin-left: -10px !important;
    margin-top: 30px;
  }
}@media (max-width: 546px) {
  .subscribe-form {   
    transform: scale(0.67);
    margin-left: -60px !important;
    margin-top: 30px;
  }
}
@media (max-width: 528px) {
  .subscribe-form {   
    transform: scale(0.67);
    margin-left: -30px !important;
    margin-top: 30px;
  }
}
@media (max-width: 508px) {
  .subscribe-form {   
    transform: scale(0.67);
    margin-left: -50px !important;
    margin-top: 30px;
  }
}
@media (max-width: 454px) {
  .subscribe-form {   
    transform: scale(0.67);
    margin-left: -70px !important;
    margin-top: 30px;
  }
}
@media (max-width: 415px) {
  .subscribe-form {   
    transform: scale(0.67);
    margin-left: -90px !important;
    margin-top: 30px;
  }
}
@media (max-width: px) {
  .subscribe-form {   
    transform: scale(0.54);
    margin-left: -130px !important;
    margin-top: 30px;
  }
}
@media (max-width: 388px) {
  .subscribe-form {   
    transform: scale(0.54);
    margin-left: -100px !important;
    margin-top: 30px;
  }
}
@media (max-width: 350px) {
  .subscribe-form {   
    transform: scale(0.54);
    margin-left: -130px !important;
    margin-top: 30px;
  }
}
.facebook,
.twitter,
.insta {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;

  margin: 0 0 -7px 10px !important;
}

.bottom-link {
  white-space: nowrap;
  display: block;
  height: 50px;
  align-items: center;
}
.bottom-link .facebook-img{
}
.inside-link{
  align-items: center !important;
  display: flex;
  align-content: center !important;
  justify-content: center;
  height: 100%;
}