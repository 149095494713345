@charset "UTF-8";
/* @import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap); */
@import url('https://fonts.cdnfonts.com/css/gilroy-bold?styles=20876,20877,20878,20879,20880');

/* @font-face {
  font-family: "NexaRegular";
  src: local("NexaRegular"),
    url("./assets/fonts/Nexa-Font/NexaRegular.otf") format("otf");
}

@font-face {
  font-family: "NexaBold";
  src: local("NexaBold"),
    url("./assets/fonts/Nexa-Font/NexaBold.otf") format("otf");
}

@font-face {
  font-family: "NexaHeavy";
  src: local("NexaHeavy"),
    url("./assets/fonts/Nexa-Font/NexaHeavy.otf") format("otf");
} */

:root {
  font-size: 0.8rem;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-size: 1.2rem; */
  /* font-family: NexaRegular, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

h1, h2, h3, h4, h5 {
  /* font-family: "NexaBold"; */
}