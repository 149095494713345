.feature-header {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  color: #27316d;
}
.vet-icon {
  max-width: 281.53px;
  height: 258px;
  background: #8d4efd;
  border-radius: 20px;
  align-items: center;
  display: flex;
}
.vet-text {
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.05em;

  color: #000000;
}
@media (min-width: 768px) {
  .feature-caption-ctn {
    margin-top: -10% !important;
  }
}

.vet-header {
  font-weight: 900 !important;
  font-size: 48px;
  text-transform: capitalize;

}
.vet-header.blue {
  text-align: left;
  color: #8d4efd;
}
.vet-header.purple {
  text-align: left;
  color: #cf2577;
}
.vet-header.green {
  text-align: right;
  color: #58c451;
}
.vet-header.light-blue {
  text-align: right;
  color: #4285f4;
}
.vet-text.right {
  text-align: right;
}
.vaccine-icon {
  max-width: 281.53px;
  height: 258px;
  background: #8d4efd;
  border-radius: 20px;
  align-items: center;
  display: flex;
  background: #cf2577;
}
.stud-icon {
  max-width: 281.53px;
  height: 258px;
  background: #8d4efd;
  border-radius: 20px;
  align-items: center;
  display: flex;
  background: #58c451;
}
.heat-icon {
  max-width: 281.53px;
  height: 258px;
  background: #8d4efd;
  border-radius: 20px;
  align-items: center;
  display: flex;
  background: #1e96fc;
}
/* .row-fix .col-sm-12 {
  margin-bottom: 2rem;
} */
@media (max-width: 500px) {
  .feature-header {
    font-size: 30px;
  }
  .vet-icon,
  .stud-icon,
  .vaccine-icon,
  .heat-icon {
    transform: scale(0.8);
  }
}
.find-wallet-header {
  font-weight: 900;
  font-size: 32px;
  color: #fdd500;
}
.find-wallet-sub-text {
  font-size: 18px;
}
.find-wallet-sub-text.left {
  text-align: left !important;
}
.find-wallet-sub-text.right {
  text-align: right !important;
}
.wallet-text-ctn {
  padding-top: 130px;
}
.withdraw-text-ctn {
  padding-top: 150px;
}
.arrow-ctn-left img {
  position: relative;
  right: -130px;
  top: -60px;
}
.arrow-ctn-right {
  margin-bottom: 100px;
}
.arrow-ctn-right img {
  position: relative;
  left: -130px;
  top: 30px;
}
.arrow-top-right,
.arrow-top-left {
  display: none;
}
@media (max-width: 950px) {
  .arrow-ctn-left,
  .arrow-ctn-right {
    display: none;
  }
  .wallet-text-ctn,
  .withdraw-text-ctn {
    padding-top: 30px;
  }
}
.phone-ctn {
  width: 567px !important;
  height: 547px !important;
  border-radius: 50%;
  background: #fbf6da;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
@media (max-width: 673px) {
  .phone-img {
    width: 200px !important;
    height: auto !important;
  }
  .phone-ctn {
    width: 320px !important;
    height: 320px !important;
  }
}
.phone-1 img {
  margin-top: 100px !important;
 
  border-radius: 30px;
}

@media (max-width: 768px) {
  .vet-header {
    font-size: 35px !important;
    text-align: center !important;
    padding-top: 30px;
  }
  .vet-text {
    text-align: center !important;
    font-size: 18px !important;
  }
  .arrow-top-left {
    display: block;
    position: absolute;
    left:20px;
    transform: scale(1.1);
    margin-top: 220px !important ;
  }
  .arrow-top-right {
    display: block;
    position: absolute;
    left: 250px;
    transform: scale(1.1);
    margin-top: -320px  !important;
  }
  .btm-info-ctn {
    margin-top: 50px;
  }
  #featuresection img {
    margin-top: 50px;
  }
  .find-wallet-sub-text {
    text-align: center !important;
    font-size: 18px;
  }

  .find-wallet-sub-text.left {
    text-align: center !important;
  }
  .find-wallet-sub-text.right {
    text-align: center !important;
  }
  .find-wallet-header {
    font-size: 36px;
    text-align: center !important;
  }
}
@media (max-width: 992px) {
  .img-div-ctn {
    text-align: center;
  }
}
