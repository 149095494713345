.app-obj,
.cloud-obj,
.person-obj {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  background: #fdd500;
  align-items: center;
  justify-content: center;
  display: flex;

  margin: 13px auto;
}
.hiw-caption {
  font-weight: bold;
  font-size: 33px;
  line-height: 33px;
  margin-bottom: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #27316d;
}
.top-ctn {
  width: 100%;
  text-align: center;
}
.arrow {
  margin-top: 75px;
}

.play-btn {
  width: 301px;
  height: 86px;
  border-radius: 30px;
  background: #fdd500 !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
}
.down-text {
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: #fff;
  white-space: nowrap;
}
a {
  text-decoration: none;
}
.top-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  margin: 0;
  text-align: start;
  letter-spacing: 0.05em;
}
.inside-btn {
  align-items: center;
  text-align: start !important;
  margin-left: 6px;
  margin-top: 10px;
}
@media (max-width: 500px) {
  .link-ctn {
    display: flex;
    justify-content: center;
  }
}
.apple-btn {
  display: flex;
  width: 301px;
  height: 86px;
  border-radius: 30px;
  background: #fff !important;
  border: 3px solid #fdd500;
  box-shadow: none !important;
}

.down-text-alt {
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: #fdd500;
  white-space: nowrap;
}

.top-text-alt {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fdd500;
  margin: 0;
  text-align: start;
  letter-spacing: 0.05em;
}
@media (min-width: 992px) {
  .play-link-alt {
    /* margin-right: 112px; */
  }
}
@media (max-width: 992px) {
  .arrow-ctn,
  .person-ctn,
  .app-ctn {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .hiw-caption {
    font-size: 25px;
  }
  .hiw-subtext {
    font-size: 18px !important;
  }
  .play-btn {
    width: 250px;
    height: 71px;
    border-radius: 30px;
    background: #fdd500 !important;
    display: flex;
    align-items: center;
    box-shadow: none !important;
  }
  .apple-btn {
    display: flex;
    width: 250px;
    height: 71px;
    border-radius: 30px;
    background: #fff !important;
    border: 3px solid #fdd500;
    box-shadow: none !important;
  }
  .top-text , .top-text-alt{
    font-size: 12px !important;
  }
  .down-text, .down-text-alt{
    font-size: 27px;
  }
  .apple-alt,.play-alt{
    transform: scale(.8);
  }
  .inside-btn{
    margin-top: 6px;
  }
}
