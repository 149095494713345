@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
.footer-section {
  background: #ffffff;
  box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.109);
}
.copyright {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  /* font-family: "Source Sans Pro", sans-serif; */
  color: #a5a5a5ce !important;
  white-space: nowrap;
}
@media (max-width: 604px) {
  .row-footer {
    justify-content: center !important;
  }
  .copyright {
    font-size: 16px;
  }
}
